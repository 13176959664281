<div class="greetings-tpl container content-container">
	<div class="row">
		<div class="main-content col-12">
			<div class="col-12 greetings-title">
				<p>{{ 'Greetings.Title' | translate }}</p>
				<p>{{ 'Greetings.Subtitle' | translate }}</p>
				<p>{{ 'Greetings.Subtitle2' | translate }}</p>
			</div>
			<div class="app-links-wrapper col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
				<div class="app-store-badge-wrapper col-sm-12 col-md-12 col-lg-5 col-xl-5">
					<a href="https://itunes.apple.com/">
						<img src="../../assets/images/app-store-badge.png" alt="app-store">
					</a>
				</div>
				<div class="google-play-badge-wrapper col-sm-12 col-md-12 col-lg-5 col-xl-5">
					<a href="https://play.google.com/">
						<img src="../../assets/images/google-play-badge.png" alt="google-play">
					</a>
				</div>
			</div>
			<div class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 redirect-to-main-website">
				<a [href]="'http://proton-tax.crio-server.com/our-services-' + lang" class="redirect-submit-btn">{{ 'Greetings.Go_back_to_portal' | translate }}</a>
			</div>
		</div>
	</div>
</div>
