// INTERNAL MODULES
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// DIRECTIVES
import { PermissionDirective, ConfirmEqualValidatorDirective, AwsTunnelDirective, LazyLoadDirective, NumbersValidatorDirective, FileDropDirective } from "../../_directives/index";

// COMPONENTS
import { AlertComponent } from "../components/alert/alert.component";

// PIPES

// EXTERNAL MODULES
import { TranslateModule } from '@ngx-translate/core';
import { TabsModule, BsDropdownModule, ModalModule, BsDatepickerModule, CarouselModule } from "ngx-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { NgSelectModule } from '@ng-select/ng-select';
import { InternationalPhoneNumberModule } from "ngx-international-phone-number-improved-final";


@NgModule({
  declarations: [
    PermissionDirective,
    LazyLoadDirective,
    AwsTunnelDirective,
    ConfirmEqualValidatorDirective,
    NumbersValidatorDirective,
    FileDropDirective,
    
    AlertComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    InternationalPhoneNumberModule
  ],
  providers: [],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    TabsModule,
    ModalModule,
    CarouselModule,
    BsDatepickerModule,
    BsDropdownModule,
    InternationalPhoneNumberModule,
    
    PermissionDirective,
    LazyLoadDirective,
    AwsTunnelDirective,
    ConfirmEqualValidatorDirective,
    NumbersValidatorDirective,
    FileDropDirective,
    
    AlertComponent
  ]
})
export class SharedLazyModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedLazyModule,
      providers: [BsModalService]
    };
  }
}
