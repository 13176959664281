<header>
	<div class="top-row"></div>
	<div class="bottom-row">
		<div class="container header" id="header">
			<div class="row main-row">
				<div class="col col-12 desktop">
					<a [routerLink]="['']" class="logo">
						<img src="{{'assets/images/FT_Logo.png'}}" alt="logo">
					</a>
					<ul class="nav-menu">
						<li class="nav-item tax-book-wrapper">
							<a class="tax-book-link" target="_blank"
							   href="../../../assets/docs/Nalogovyj_putevoditel_po_Germanii.pdf">
								{{ 'Header.Tax_guide_in_germany' | translate }}
							</a>
						</li>
					</ul>
					<ul class="nav-menu">
						<li class="nav-item sign-in">
							<a class="sign_up_btn" [href]="myProfileLink">
								<i class="fas fa-sign-in-alt"></i>
								{{ 'Header.Link_to_my_profile' | translate }}
							</a>
						</li>
						<!--<li *ngIf="!user && router.url !== '/registration'" class="nav-item sign-up">
						  <a *ngIf="!user" class="sign_up_btn" [routerLink]="['registration']">
							<i class="fas fa-user-plus"></i>
							{{ 'Header.Sign_up_btn' | translate }}
						  </a>
						</li>
						<li *ngIf="!user" class="nav-item login-dropdown">
						  <span class="dropdown log_in_dropdown_menu_btn" id="log_in_dropdown_menu" (click)="toggleDropdown()" aria-haspopup="true" aria-expanded="false">
							<i class="fas fa-sign-in-alt"></i>
							{{ 'Header.Log_in_dropdown_btn' | translate }}
						  </span>
						  <div [ngClass]="{'show': loginDropdownToggle}" class="login-dropdown-menu dropdown-menu" aria-labelledby="log_in_dropdown_menu">
							<app-login></app-login>
						  </div>
						</li>
						<li *ngIf="user" class="nav-item survey" [routerLinkActive]="['is-active']">
						  <a *ngIf="user" class="survey_btn" [routerLink]="['survey']">
							{{ 'Header.Survey' | translate }}
						  </a>
						</li>
						<li *ngIf="user" class="nav-item user-profile" [routerLinkActive]="['is-active']">
						  <a *ngIf="user" class="user_profile_btn" [routerLink]="['profile']">
							<img src="{{'assets/images/user.png'}}" alt="user photo">
							{{user.name}}
						  </a>
						</li>
						<li *ngIf="user" class="nav-item logout">
						  <a class="logout_btn" (click)="logout()">{{ 'Header.Logout' | translate }}</a>
						</li>-->
						<li class="nav-item language" [class.front-page]="!user">
							<div class="dropdown lang_dropdown_menu_wrapper">
								<button class="lang_dropdown_menu_btn" type="button" id="lang_dropdown_menu"
										data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span *ngIf="lang">{{lang === 'uk' ? 'UA' : lang}}</span>
									<i class="fas fa-chevron-down"></i>
								</button>
								<div class="lang-dropdown-menu dropdown-menu" aria-labelledby="lang_dropdown_menu">
									<a *ngIf="lang !== 'de'" (click)="useLanguage('de')">DE</a>
									<a *ngIf="lang !== 'ru'" (click)="useLanguage('ru')">RU</a>
									<a *ngIf="lang !== 'uk'" (click)="useLanguage('uk')">UA</a>
									<a *ngIf="lang !== 'pl'" (click)="useLanguage('pl')">PL</a>
									<a *ngIf="lang !== 'en'" (click)="useLanguage('en')">EN</a>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="col col-12 mobile" #mobilemenu>
					<a [routerLink]="['']" class="logo">
						<img src="{{'assets/images/FT_Logo.png'}}" alt="logo">
					</a>
					<i class="fas fa-bars" (click)="toggleMobileMenu()"></i>
					<div class="mobile-menu-wrapper">
						<div class="top-row-mobile"></div>
						<div class="nav-menu main-menu book-menu">
							<div class="nav-item tax-book-wrapper">
								<a class="tax-book-link" target="_blank"
								   href="../../../assets/docs/Nalogovyj_putevoditel_po_Germanii.pdf">
									{{ 'Header.Tax_guide_in_germany' | translate }}
								</a>
							</div>
						</div>
						<div class="nav-menu main-menu">
							<div class="nav-item sign-in">
								<a class="sign_up_btn" [href]="myProfileLink">
									<i class="fas fa-sign-in-alt"></i>
									{{ 'Header.Link_to_my_profile' | translate }}
								</a>
							</div>
							<!--<div *ngIf="!user && router.url !== '/registration'" class="nav-item sign-up">
							  <a *ngIf="!user" class="sign_up_btn" [routerLink]="['registration']">
								<i class="fas fa-user-plus"></i>
								{{ 'Header.Sign_up_btn' | translate }}
							  </a>
							</div>
							<div *ngIf="!user" class="nav-item login-dropdown">
							  <span class="dropdown log_in_dropdown_menu_btn" id="log-in-dropdown-menu-mobile" (click)="toggleDropdown()">
								<i class="fas fa-sign-in-alt"></i>
								{{ 'Header.Log_in_dropdown_btn' | translate }}
							  </span>
							  <div *ngIf="loginDropdownToggle" class="log-in-dropdown-menu-mobile">
								<app-login></app-login>
							  </div>
							</div>
							<div *ngIf="user" class="nav-item survey">
							  <a class="survey_btn" [routerLink]="['survey']">
								{{ 'Header.Survey' | translate }}
							  </a>
							</div>
							<div *ngIf="user" class="nav-item user-profile">
							  <a class="user_profile_btn" [routerLink]="['profile']">
								<img src="{{'assets/images/user.png'}}" alt="user photo">
								{{user.name}}
							  </a>
							</div>
							<div *ngIf="user" class="nav-item logout">
							  <a class="logout_btn" (click)="logout()">{{ 'Header.Logout' | translate }}</a>
							</div>-->
						</div>
						<div class="nav-menu language-menu">
							<div class="nav-item" [class.active]="lang === 'de'" (click)="useLanguage('de')">
								<div>DE</div>
							</div>
							<div class="nav-item" [class.active]="lang === 'ru'" (click)="useLanguage('ru')">
								<div>RU</div>
							</div>
							<div class="nav-item" [class.active]="lang === 'uk'" (click)="useLanguage('uk')">
								<div>UA</div>
							</div>
							<div class="nav-item" [class.active]="lang === 'pl'" (click)="useLanguage('pl')">
								<div>PL</div>
							</div>
							<div class="nav-item" [class.active]="lang === 'en'" (click)="useLanguage('en')">
								<div>EN</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
