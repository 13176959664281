import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.less']
})
export class SurveyComponent implements OnInit {
  USER;
  fullReload = false;
  constructor() {}
  ngOnInit() {
    this.getUserData();
  }
  
  public fullSurveyReload(): void {
    this.fullReload = true;
  }
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }
}
