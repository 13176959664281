import {Component, ElementRef, OnInit, Renderer2, ViewChild} from "@angular/core";
import {AlertService, RegistrationService} from "../../_services";
import {ActivatedRoute, Router} from "@angular/router";

import {FormControl, FormGroup, FormBuilder, Validators} from "@angular/forms";
import {AcceptSmsComponent} from "../accept-sms/accept-sms.component";
import {SetupPwdComponent} from "../setup-pwd/setup-pwd.component";

@Component({
	selector: "app-registration",
	templateUrl: "./registration.component.html",
	styleUrls: ["./registration.component.less"]
})
export class RegistrationComponent implements OnInit {
	/*@ViewChild(AcceptSmsComponent) child;
	@ViewChild(SetupPwdComponent) child2;*/

	userPassedSurvey;

	userTempData: any = {
		phone_code: "",
		phone_number: ""
	};

	registrationForm: FormGroup;

	/*stepsTrigger: {
	  first_step: boolean,
	  accept_sms: {
		loading: boolean,
		success: boolean
	  },
	  set_password: {
		loading: boolean,
		success: boolean
	  }
	} = {
	  first_step: false,
	  accept_sms: {
		loading: true,
		success: false
	  },
	  set_password: {
		loading: true,
		success: false
	  }
	};*/

	constructor(
		private _RegistrationService: RegistrationService,
		private router: Router,
		private route: ActivatedRoute,
		private _alertService: AlertService,
		private fb: FormBuilder,
		private elem: ElementRef,
		private renderer: Renderer2
	) {
		this.userPassedSurvey = this.route.snapshot.queryParams["passed"];

	}

	ngOnInit() {
		this.createForm();
	}

	/*receiveMessage($event) {
	  this.stepsTrigger = $event;
	}*/

	onchangeValidation(): void {
		let dialCode = this.elem.nativeElement.querySelector(".phone_number").getAttribute("dialCode");
		let userInput = this.registrationForm.controls["phone_number"].value;

		if (dialCode === "false" && userInput.length || userInput === "+" + dialCode) {
			this.registrationForm.controls["phone_number"].setErrors({"wrong_number": true});
		}
	}

	/*restartRegistration(): void {
	  this.stepsTrigger = {
		first_step: false,
		accept_sms: {
		  loading: true,
		  success: false
		},
		set_password: {
		  loading: true,
		  success: false
		}
	  };
	  this.child.acceptSmsForm.reset();
	  this.child2.setupPwdForm.reset();
	}*/

	createForm(): void {
		const emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
		this.registrationForm = this.fb.group({
			name: ["", [Validators.required, Validators.maxLength(50)]],
			second_name: ["", [Validators.required, Validators.maxLength(50)]],
			phone_number: ["", [Validators.required]],
			email: ["", [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(40)]],
			own_income: ["", []],
			partner_income: ["", []]
		});
	}

	public registerMobileUser(): void {
		this.onchangeValidation();
		let objToSent = {
			"phone_code": "+" + this.elem.nativeElement.querySelector(".phone_number").getAttribute("dialCode"),
			"phone_number": null,
			"name": this.registrationForm.value.name,
			"second_name": this.registrationForm.value.second_name,
			"email": this.registrationForm.value.email/*,
			"own_income": this.registrationForm.value.own_income,
			"partner_income": this.registrationForm.value.partner_income*/
		};
		objToSent["phone_number"] = this.registrationForm.value.phone_number.replace(objToSent.phone_code, "");
		/*this.userTempData = {
			phone_code: objToSent.phone_code,
			phone_number: objToSent.phone_number
		};*/
		console.log(objToSent);

		/*localStorage.setItem("tempUserData", JSON.stringify({
			phone_code: objToSent.phone_code,
			phone_number: objToSent.phone_number,
			phone_token: 'THIS IS A PHONE TOKEN',
			user_id: 'qqqqqqq'
		}));*/
		// this.router.navigate(['/sms-confirmation']);

		this._RegistrationService.registerMobileUser(objToSent)
		.subscribe(res => {
			localStorage.setItem("tempUserData", JSON.stringify({
				phone_code: objToSent.phone_code,
				phone_number: objToSent.phone_number,
				phone_token: res.phone_token,
				user_id: res.user_id
			}));
			this.router.navigate(['/sms-confirmation']);
			/*this.stepsTrigger = {
			  first_step: true,
			  accept_sms: {
				loading: false,
				success: false
			  },
			  set_password: {
				loading: true,
				success: false
			  }
			};*/
		}, error => {
			console.log(error);
			if (error.error.message === 'This phone number is already used') {
				this._alertService.error("Alert.The_phone_number_is_already_in_use");
			} else {
				this._alertService.error("Alert.Opps_something_went_wrong");
			}
		});
	}

}
