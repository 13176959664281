import {Component, ElementRef, OnInit, Renderer2, Input, Output, EventEmitter} from "@angular/core";
import {AlertService, RegistrationService} from "../../_services";
import {ActivatedRoute, Router} from "@angular/router";

import {FormControl, FormGroup, FormBuilder, Validators} from "@angular/forms";

@Component({
	selector: "app-accept-sms",
	templateUrl: "./accept-sms.component.html",
	styleUrls: ["./accept-sms.component.less"]
})
export class AcceptSmsComponent implements OnInit {
	/*@Input () stepsTrigger: any;
	@Output() messageEvent = new EventEmitter<string>();*/
	
	acceptSmsForm: FormGroup;
	
	constructor(
		private _RegistrationService: RegistrationService,
		private router: Router,
		private route: ActivatedRoute,
		private _alertService: AlertService,
		private fb: FormBuilder
	) {
	}
	
	ngOnInit() {
		this.createForm();
	}
	
	createForm(): void {
		this.acceptSmsForm = this.fb.group({
			sms_code: ["", [Validators.required, Validators.maxLength(10)]]
		});
	}
	
	public submitSms(): void {
		let objToSent = JSON.parse(localStorage.getItem("tempUserData"));
		objToSent["sms_code"] = this.acceptSmsForm.value.sms_code;
		
		console.log(objToSent);
		
		/*objToSent["password_token"] = 'THIS IS A PASSWORD TOKEN';
		localStorage.setItem("tempUserData", JSON.stringify(objToSent));
		this.router.navigate(["/password-setup"]);*/
		
		this._RegistrationService.acceptConfirmationSMS(objToSent)
		.subscribe(res => {
			console.log(res);
			objToSent["password_token"] = res['pwd_token'];
			localStorage.setItem("tempUserData", JSON.stringify(objToSent));
			/*localStorage.setItem("tempUserData", JSON.stringify({
				password_token: res.pwd_token,
				user_id: res.user_id
			}));*/
			this.router.navigate(["/password-setup"]);
			/*this.stepsTrigger = {
			  first_step: true,
			  accept_sms: {
				loading: false,
				success: true
			  },
			  set_password: {
				loading: false,
				success: false
			  }
			};
			this.messageEvent.emit(this.stepsTrigger);*/
			
		}, error => {
			console.log(error);
			this._alertService.error("Alert.Opps_something_went_wrong");
		});
	}
}
