import {Component, OnInit} from "@angular/core";
import {Router, ActivatedRoute} from "@angular/router";

@Component({
	selector: "app-front-page",
	templateUrl: "./front-page.component.html",
	styleUrls: ["./front-page.component.less"]
})
export class FrontPageComponent implements OnInit {
	constructor(
		private router: Router
	) {
	}
	
	ngOnInit() {
	}
	
	public startSurvey(): void {
		localStorage.removeItem("surveyDataV2");
		localStorage.removeItem("periodsSelected1");
		this.router.navigate(["survey"]);
	}
	
}
