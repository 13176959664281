import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {EnvService} from "./env.service";

@Injectable()
export class UserProfileService {
	
	private USER = JSON.parse(localStorage.getItem("currentUser"));
	
	getUserData(): void {
		this.USER = JSON.parse(localStorage.getItem("currentUser"));
	}
	
	constructor(
		private env: EnvService,
		private http: HttpClient
	) {
	}
	
	public getPeriods(): Observable<any> {
		const url = `${this.env.apiBase}/period`;
		return this.http.get<Object>(url);
	}
	
	public getMobileUsers(): Observable<any> {
		const url = `${this.env.apiBase}/user`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public getAttributes(): Observable<any> {
		const url = `${this.env.apiBase}/attribute`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public getSubmittedUserDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("with_pdf", '1')
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public downloadDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document/download`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_owner_id", this.USER.user_id)
		.append("language_code", data.language_code)
		.append("sub_category_id", data.sub_category_id)
		.append("period_id", data.period_id)
		.append("type", "1")
		.append("document_id", data.document_id);
		
		return this.http.get(url, {
			params: params,
			responseType: "arraybuffer"
		});
	}
	
	public getRegisteredLanguages(): Observable<any> {
		const url = `${this.env.apiBase}/language`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public getSpecificIsoLangNames(array): any {
		return this.http.get<any>("/assets/ISO-631-1.json")
		.map((res) => {
			let arrayFixed = [];
			for (let i = 0; i < array.length; i++) {
				for (let key of Object.keys(res)) {
					if (array[i].lang_code === key.toUpperCase()) {
						for (let key2 of Object.keys(res[key])) {
							array[i]["name"] = res[key].name;
							array[i]["native_name"] = res[key].nativeName;
							arrayFixed.push(array[i]);
							break;
						}
					}
				}
			}
			return arrayFixed;
		});
	}
	
	public postDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.post<Object>(url, data, {params});
	}
	
	public deleteDocumentFile(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document-item/${data.id}`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.delete<Object>(url, {params});
	}
	
	public deleteDocument(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("document_id", data.id);
		return this.http.delete<Object>(url, {params});
	}
	
	public changeUserPassword(data): Observable<any> {
		const url = `${this.env.apiBase}/user/change-password`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("old_password", data.previous_password)
		.append("new_password", data.password)
		.append("password_confirmation", data.password_confirmation);
		return this.http.put(url, {}, {
			params: params
		});
	}
	
	public downloadTaxCalcAndDeclarations(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document/download`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("language_code", data.language_code)
		.append("user_owner_id", data.user_id)
		.append("type", data.type)
		.append("period_id", (!data.period_id) ? "" : data.period_id);
		
		return this.http.get(url, {
			params: params,
			responseType: "arraybuffer"
		});
	}
	
	public getSubmittedDeclarations(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "2")
		.append("with_pdf", '1')
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public getSubmittedTaxCalculations(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "3")
		.append("with_pdf", '1')
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}
	
	public postTaxCalcAndDeclarations(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("user_owner_id", data.user_id)
		.append("userId", this.USER.user_id);
		return this.http.post<Object>(url, data, {params});
	}
	
	public feedbackMessageSend(data): Observable<any> {
		const url = `${this.env.apiBase}/user/email-to-adviser`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("subject", data.subject)
		.append("message", data.message);
		return this.http.post<Object>(url, data["attachments"]["files_array"].length ? data["attachments"] : "", {params});
	}
	
}
