import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { EnvService } from "./env.service";

@Injectable()
export class SurveyService {
  private USER;
  
  constructor(
    private env: EnvService,
    private http: HttpClient
  ) {
    this.getUserData();
  }
  
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  public getPeriods(): Observable<any> {
    const url = `${this.env.apiBase}/period`;
    return this.http.get<Object>(url);
  }
  
  public unauthGetQuestion(data): Observable<any> {
    const url = `${this.env.apiBase}/user/question`;
    let params = new HttpParams()
      .append('userId', '')
      .append('question_id', data.question_id);
    return this.http.get<Object>(url, {params});
  }
  public unauthSubmitAnswer(data): Observable<any> {
    const url = `${this.env.apiBase}/user/question`;
    let params = new HttpParams()
      .append('question_id', data.question_id)
      .append('answer_id', data.answer_id)
      .append('value', data.value);
    return this.http.get<Object>(url, {params});
    
  }
  
  
  /* TWO METHODS BELOW ARE FOR USERS THAT AREN'T REGISTERED */
  public getQuestion(previousQuestion?: number[], value?): Observable<any> {
    this.getUserData();
    let qq;
    if (previousQuestion && previousQuestion.length) {
      qq = previousQuestion[previousQuestion.length - 1];
    } else {
      qq = '';
    }
    
    if (this.USER) {
      const url = `${this.env.apiBase}/auth/user/question`;
      let params = new HttpParams()
        .append('userId', this.USER.user_id)
        .append('question_id', qq)
        .append('value', (value) ? value : '');
      return this.http.get<Object>(url, {params});
    } else {
      const url = `${this.env.apiBase}/user/question`;
      let params = new HttpParams()
        .append('userId', '')
        .append('question_id', qq.toString());
      return this.http.get<Object>(url, {params});
    }
  }
  
  public getQuestionQ(data): Observable<any> {
    this.getUserData();
    const url = `${this.env.apiBase}/auth/user/question`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('question_id', data.question_id)
      .append('period_id', data.period_id);
    return this.http.get<Object>(url, {params});
  }
  
  public submitAnswer(data): Observable<any> {
    this.getUserData();
    if (this.USER) {
      const url = `${this.env.apiBase}/auth/user/question`;
      let params = new HttpParams()
        .append('question_id', data.question_id)
        .append('answer_id', data.answer_id)
        .append('userId', this.USER.user_id)
        .append('period_id', data.period_id)
        .append('value', (data.value) ? data.value : '');
      return this.http.get<Object>(url, {params});
    } else {
      const url = `${this.env.apiBase}/user/question`;
      let params = new HttpParams()
        .append('question_id', data.question_id)
        .append('answer_id', data.answer_id.toString())
        .append('userId', '')
        .append('value', (data.value) ? data.value : '');
      return this.http.get<Object>(url, {params});
    }
  }
 
  public noChangesInNextPeriod(data): Observable<any> {
    this.getUserData();
    const url = `${this.env.apiBase}/auth/user/question/repeat`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('old_period_id', data.old_period_id)
      .append('new_period_id', data.new_period_id);
    return this.http.get<Object>(url, {params});
  }
  public resetUserAttrs(data): Observable<any> {
    this.getUserData();
    const url = `${this.env.apiBase}/auth/user/question/reset`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('period_id', data.period_ids);
    return this.http.get<Object>(url, {params});
  }
  
}
