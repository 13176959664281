import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {SurveyService, SharedService, EnvService, AlertService} from "../../_services";
import {ActivatedRoute, Router} from "@angular/router";
import {BsModalService} from "ngx-bootstrap";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {FormGroup, FormBuilder, Validators, FormControl} from "@angular/forms";
import * as moment_ from "moment";
import {NgSelectComponent} from "@ng-select/ng-select";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: "app-unauth-survey",
	templateUrl: "./unauth-survey.component.html",
	styleUrls: ["./unauth-survey.component.less"]
})
export class UnauthSurveyComponent implements OnInit {
	
	@ViewChild('officialConfirmationModal') officialConfirmationModal;
	
	modalRef: BsModalRef;
	periodsForm: FormGroup;
	customAnswerValueForm: FormGroup;
	
	lang: any = {active: localStorage.getItem("lang")};
	attemptsCounter = JSON.parse(localStorage.getItem('attemptsCounter'));
	
	answerIsNotChosen = true;
	continueSurveyAfterYearSelection = false;
	askUserAboutChanges = false;
	passedSurvey = false;
	
	periods: {
		id: number,
		year: string
	}[];
	QAObject: {
		id: number;
		description: string;
		description2: string;
		tree_end?: number;
		registration?: number;
		answer_chosen?: boolean;
		multi_answer?: boolean;
		type?: number;
		answers: any[];
	};
	periodsSelected: any = [];
	periodsSelected1: any = [];
	activeYear: any = {};
	yearRestriction: {} = {
		start: null,
		end: null
	};
	customMultipleValues: {}[] = [];
	
	myProfileLink: string = '';
	
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private _SurveyService: SurveyService,
		private modalService: BsModalService,
		private fb: FormBuilder,
		private _SharedService: SharedService,
		private translate: TranslateService,
		public _EnvService: EnvService,
		private _AlertService: AlertService
	) {
		this.myProfileLink = this._EnvService["myProfileLink"];
	}
	
	ngOnInit() {
		this.getPeriods();
		if (this.lang['active'] === null) {
			this.lang['active'] = 'ru';
		} else {
			this.lang['active'] = this.lang['active'] === 'uk' ? 'ua' : this.lang['active'];
		}
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			this.lang.active = res;
			this.lang['active'] = this.lang['active'] === 'uk' ? 'ua' : this.lang['active'];
		});
	}
	
	openOfficialConfirmationModal(): void {
		this.modalRef = this.modalService.show(
			this.officialConfirmationModal,
			Object.assign({
				'class': 'official-confirmation-modal modal-dialog-centered',
				'ignoreBackdropClick': true
			})
		);
	}
	public officialConfirmation(): void {
		this.periodsSelected.forEach((obj) => {
			if (obj['active']) {
				this.QAObject['attributes_id'].forEach(child_obj => {
					if (child_obj.type === 6) {
						let treeEndAttr = child_obj;
						treeEndAttr['value'] = '';
						obj['attrs']['attributes_id'].push(treeEndAttr);
					}
				})
				
			}
		})
		this.closeModal();
		this.router.navigate(['/registration']);
		localStorage.setItem("surveyDataV2", JSON.stringify(this.periodsSelected));
	}
	
	public periodsFormInit(): void {
		this.periodsForm = this.fb.group({
			periods: [[], Validators.required]
		});
	}
	public customAnswerValueFormInit(): void {
		this.yearRestriction = {
			start: moment_(`${this.activeYear.year}-01-01`).startOf("year").toDate(),
			end: moment_(`${this.activeYear.year}-01-01`).endOf("year").toDate()
		};
		this.customAnswerValueForm = this.fb.group({
			value: ["", [Validators.required]],
			date: [this.yearRestriction["start"], [Validators.required]],
			mutipleValue: ["", [Validators.required]]
		});
	}
	
	// COMMON METHODS
	public getPeriods(): void {
		this._SurveyService.getPeriods()
		.subscribe(res => {
			this.periods = res.result;
			this.periodsFormInit();
		});
	}
	public chooseAnswers(i, clear?): void {
		if (i && !this.QAObject.multi_answer) {
			// IF USER ANSWERED AND THiS IS A SINGLE-ANSWER QUESTION
			for (let q = 0; q < this.QAObject.answers.length; q++) {
				this.QAObject.answers[q].answer_chosen = false;
			}
			i["answer_chosen"] = true;
			this.answerIsNotChosen = false;
		} else if (i && this.QAObject.multi_answer) {
			// IF USER ANSWERED AND THiS IS A MULTI-ANSWER QUESTION
			if (i["answer_chosen"]) {
				i["answer_chosen"] = false;
				this.answerIsNotChosen = true;
				for (let q = 0; q < this.QAObject.answers.length; q++) {
					if (this.QAObject.answers[q].answer_chosen) {
						this.answerIsNotChosen = false;
					}
				}
			} else {
				i["answer_chosen"] = true;
				this.answerIsNotChosen = false;
			}
		}
		
		if (clear) {
			if (this.QAObject) {
				for (let q = 0; q < this.QAObject.answers.length; q++) {
					this.QAObject.answers[q].answer_chosen = false;
				}
			}
			this.answerIsNotChosen = true;
		}
	}
	public proceedToRegistration(): void {
		this.router.navigate(["/registration"]);
	}
	public addMoreCustomValueInputs(): void {
		this.customMultipleValues.push(
			{
				id: this.customMultipleValues.length,
				name: `customValue${this.customMultipleValues.length}`,
				value: ""
			}
		);
		this.customAnswerValueForm.addControl(
			this.customMultipleValues[this.customMultipleValues.length - 1]["name"],
			new FormControl("")
		);
	}
	public closeSelect(select: NgSelectComponent): void {
		select.close();
	}
	public closeModal(): void {
		this.chooseAnswers(null, true);
		this.modalRef.hide();
	}
	public isEmpty(obj): boolean {
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				return false;
			}
		}
		return true;
	}
	
	// GLOBAL GET QUESTION AND ANSWER QUESTION METHODS
	public unauthGetQuestion(question_id): void {
		this._SurveyService.unauthGetQuestion({
			question_id: question_id
		}).subscribe(res => {
			this.QAObject = res.result;
			if (this.QAObject["tree_end"] === -1) {
				this.QAObject["tree_end"] = 0;
			}
			for (let i = 0; i < this.QAObject.answers.length; i++) {
				for (let z = 0; z < this.QAObject.answers[i].attributes_id.length; z++) {
					if (this.QAObject.answers[i].attributes_id[z].type > 0) {
						this.QAObject["type"] = this.QAObject.answers[i].attributes_id[z];
					}
				}
			}
			console.log(this.QAObject);
			this.answerIsNotChosen = true;
			this.customAnswerValueFormInit();
			window.scroll(0, 0);
		}, err => {
			console.log(err);
		});
		
	}
	
	public unauthSubmitAnswer(question_id, answer_id, answer_value?): void {
		this._SurveyService.unauthSubmitAnswer({
			question_id: question_id,
			answer_id: answer_id,
			value: answer_value
		}).subscribe(res => {
			console.log(res);
			this.QAObject = res.result;
			if (this.QAObject["tree_end"] === -1) {
				this.QAObject["tree_end"] = 0;
			} else if (this.QAObject["tree_end"]) {
				console.log(this.periodsSelected);
				this.attemptsCounter = JSON.parse(localStorage.getItem('attemptsCounter'));
				if (this.attemptsCounter === null) {
					this.attemptsCounter = 1;
					localStorage.setItem('attemptsCounter', this.attemptsCounter);
				} else {
					this.attemptsCounter += 1;
					localStorage.setItem('attemptsCounter', this.attemptsCounter);
				}
				this.openOfficialConfirmationModal();
				/*this.periodsSelected.forEach((obj) => {
					if (obj['active']) {
						this.QAObject['attributes_id'].forEach(child_obj => {
							if (child_obj.type === 6) {
								let treeEndAttr = child_obj;
								treeEndAttr['value'] = '';
								obj['attrs']['attributes_id'].push(treeEndAttr);
							}
						})
						
					}
				})*/
			}
			for (let ii = 0; ii < this.QAObject.answers.length; ii++) {
				for (let z = 0; z < this.QAObject.answers[ii].attributes_id.length; z++) {
					if (this.QAObject.answers[ii].attributes_id[z].type > 0) {
						this.QAObject["type"] = this.QAObject.answers[ii].attributes_id[z];
					}
				}
			}
			
			this.periodsSelected.forEach(res1 => {
				if (res1["active"]) {
					res1["attrs"]["question_id"] = res.result["id"];
				}
			});
			
			this.answerIsNotChosen = true;
			this.periodsSelected1.forEach(obj => {
				if (obj.period_id === this.activeYear.id) {
					obj['items'].push({
						question_id: question_id,
						answer_ids: answer_id
					})
				}
			})
			localStorage.setItem("periodsSelected1", JSON.stringify(this.periodsSelected1));
			localStorage.setItem("surveyDataV2", JSON.stringify(this.periodsSelected));
			window.scroll(0, 0);
			console.log(this.QAObject);
			if (this.QAObject.tree_end) {
				localStorage.removeItem("passed");
				this.passedSurvey = false;
			}
		}, err => {
			console.log(err);
		});
	}
	
	public setPeriods(template: TemplateRef<any>): void {
		this.attemptsCounter = JSON.parse(localStorage.getItem('attemptsCounter'));
		if (this.attemptsCounter < 3) {
			this.periodsSelected = JSON.parse(localStorage.getItem("surveyDataV2"));
			this.periodsSelected1 = JSON.parse(localStorage.getItem("periodsSelected1"))
			let yearChosen = JSON.parse(JSON.stringify(this.periodsForm.value.periods));
			yearChosen["attrs"] = {};
			yearChosen["active"] = true;
			let yearChosen1 = {
				period_id: this.periodsForm.value.periods.id,
				items: []
			}
			this.activeYear = yearChosen;
			if (this.periodsSelected === null || !this.periodsSelected.length) {
				this.periodsSelected = [];
				this.periodsSelected.push(yearChosen);
				this.periodsSelected1 = [];
				this.periodsSelected1.push(yearChosen1);
				this.unauthGetQuestion("");
				setTimeout(() => {
					this.continueSurveyAfterYearSelection = true;
				}, 500);
				localStorage.setItem("surveyDataV2", JSON.stringify(this.periodsSelected));
				localStorage.setItem("periodsSelected1", JSON.stringify(this.periodsSelected1));
			} else {
				const hasDuplicate = this.periodsSelected.some(obj => obj["id"] === yearChosen["id"]);
				this.periodsSelected.forEach(obj => {
					obj["active"] = false;
				});
				if (hasDuplicate) {
					this.modalRef = this.modalService.show(
						template,
						Object.assign({"class": "repetition-alert-modal modal-dialog-centered"})
					);
				} else {
					this.periodsSelected.push(yearChosen);
					this.periodsSelected1.push(yearChosen1);
					localStorage.setItem("surveyDataV2", JSON.stringify(this.periodsSelected));
					localStorage.setItem("periodsSelected1", JSON.stringify(this.periodsSelected1));
					this.askUserAboutChanges = false;
					this.continueSurveyAfterYearSelection = true;
					this.unauthGetQuestion("");
				}
				window.scroll(0, 0);
			}
			if (this.customAnswerValueForm) {
				this.customAnswerValueForm.reset();
				this.customMultipleValues = [];
			}
		} else {
			this._AlertService.error('Alert.Max_attempts_survey', false);
		}
	}
	
	public repetitionAlertConfirmation(): void {
		this.closeModal();
		let yearChosen = this.activeYear;
		
		for (let i = 0; i < this.periodsSelected.length; i++) {
			if (this.periodsSelected[i]["id"] === yearChosen["id"]) {
				this.periodsSelected[i] = yearChosen;
				let cutOut = this.periodsSelected.splice(i, 1)[0];
				this.periodsSelected.push(cutOut);
				break;
			}
		}
		
		this.periodsSelected1.forEach(obj => {
			if (obj.period_id === this.activeYear.id) {
				obj['items'] = [];
			}
		})
		
		this.unauthGetQuestion("");
		localStorage.setItem("periodsSelected1", JSON.stringify(this.periodsSelected1));
		localStorage.setItem("surveyDataV2", JSON.stringify(this.periodsSelected));
		setTimeout(() => {
			this.continueSurveyAfterYearSelection = true;
			this.askUserAboutChanges = false;
		}, 500);
	}
	
	public unauthCustomAnswerValue(type): void {
		this.periodsSelected = JSON.parse(localStorage.getItem("surveyDataV2"));
		let multipleString = "";
		if (type["type"] === 2) {
			this.QAObject.type["value"] = moment_(this.customAnswerValueForm.value.date, "DD-MM-YYYY").format("YYYY-MM-DD");
		} else if (type["type"] === 1) {
			this.QAObject.type["value"] = this.customAnswerValueForm.value.value;
		} else {
			multipleString = this.customAnswerValueForm.controls["mutipleValue"].value;
			for (let i = 0; i < this.customMultipleValues.length; i++) {
				if (this.customAnswerValueForm.controls[this.customMultipleValues[i]["name"]].value) {
					multipleString = multipleString + "~" + this.customAnswerValueForm.controls[this.customMultipleValues[i]["name"]].value;
				}
			}
			this.QAObject.type["value"] = multipleString;
		}
		
		this.periodsSelected.forEach(res => {
			if (res["active"]) {
				if (this.isEmpty(res["attrs"])) {
					res["attrs"] = {
						question_id: this.QAObject.id,
						answer_id: this.QAObject.answers[0].id,
						registration: this.QAObject.registration,
						attributes_id: [this.QAObject.type],
						previous_questions: [this.QAObject.id]
					};
				} else {
					res["attrs"].attributes_id.push(this.QAObject.type);
					res["attrs"].previous_questions.push(this.QAObject.id);
					res["attrs"] = {
						question_id: this.QAObject.id,
						answer_id: this.QAObject.answers[0].id,
						registration: this.QAObject["registration"],
						attributes_id: res["attrs"].attributes_id,
						previous_questions: res["attrs"].previous_questions
					};
				}
			}
		});
		
		this.unauthSubmitAnswer(this.QAObject.id, this.QAObject.answers[0].id, "");
		
	}
	
	public unauthAnswerQuestion(): void {
		this.periodsSelected = JSON.parse(localStorage.getItem("surveyDataV2"));
		let objToSend = {};
		let arr = [];
		let answers_array = [];
		for (let q = 0; q < this.QAObject.answers.length; q++) {
			if (this.QAObject.answers[q].answer_chosen) {
				objToSend["question_id"] = this.QAObject.id;
				answers_array.push(this.QAObject.answers[q].id);
				for (let f = 0; f < this.QAObject.answers[q].attributes_id.length; f++) {
					this.QAObject.answers[q].attributes_id[f]["value"] = "";
					arr.push(this.QAObject.answers[q].attributes_id[f]);
				}
			}
		}
		objToSend["answer_id"] = answers_array;
		objToSend["attributes_id"] = arr;
		
		this.periodsSelected.forEach(res => {
			if (res["active"]) {
				if (this.isEmpty(res["attrs"])) {
					res["attrs"] = {
						question_id: objToSend["question_id"],
						answer_id: objToSend["answer_id"],
						attributes_id: objToSend["attributes_id"],
						previous_questions: [objToSend["question_id"]]
					};
				} else {
					let b = res["attrs"].attributes_id.concat(objToSend["attributes_id"]);
					b = b.filter((item, index, self) =>
						index === self.findIndex((t) => (
							t.id === item.id
						))
					);
					res["attrs"].previous_questions.push(objToSend["question_id"]);
					res["attrs"] = {
						question_id: objToSend["question_id"],
						answer_id: objToSend["answer_id"],
						attributes_id: b,
						previous_questions: res["attrs"].previous_questions
					};
				}
			}
		});
		
		this.unauthSubmitAnswer(objToSend["question_id"], objToSend["answer_id"], "");
	}
	
	public unauthRerunSurvey(rerun): void {
		console.log(this.QAObject);
		if (this.QAObject.tree_end) {
			localStorage.setItem("passed", "true");
			this.passedSurvey = true;
		}
		this.periodsSelected = JSON.parse(localStorage.getItem("surveyDataV2"));
		this.periodsSelected1 = JSON.parse(localStorage.getItem("periodsSelected1"));
		if (rerun) {
			console.log('RERUN')
			for (let i = this.periodsSelected.length; i--;) {
				if (this.periodsSelected[i].active) {
					this.periodsSelected[i]["attrs"] = {};
					this.unauthGetQuestion("");
					break;
				}
			}
			this.periodsSelected1.forEach(obj => {
				if (obj.period_id === this.activeYear.id) {
					obj['items'] = [];
				}
			})
		} else {
			console.log('NORERUN');
			this.continueSurveyAfterYearSelection = false;
			if (this.periodsSelected.length === 1) {
				this.periodsSelected = [];
				this.periodsSelected1 = [];
				localStorage.removeItem("surveyDataV2");
				localStorage.removeItem("periodsSelected1");
			} else {
				for (let i = this.periodsSelected.length; i--;) {
					if (this.periodsSelected[i].active) {
						let index = this.periodsSelected.indexOf(this.periodsSelected[i]);
						if (index !== -1) {
							this.periodsSelected.splice(index, 1);
							break;
						}
					}
				}
				
				for (let i = this.periodsSelected1.length; i--;) {
					if (this.periodsSelected1[i].period_id === this.activeYear.id) {
						let index = this.periodsSelected1.indexOf(this.periodsSelected1[i]);
						if (index !== -1) {
							this.periodsSelected1.splice(index, 1);
							break;
						}
					}
				}
			}
			window.scroll(0, 0);
		}
		this.customAnswerValueForm.controls["value"].setValue("");
		this.customAnswerValueForm.controls["date"].setValue(moment_().format("DD-MM-YYYY"));
		this.periodsForm.reset();
		localStorage.setItem("surveyDataV2", JSON.stringify(this.periodsSelected));
		
		/*this.periodsSelected1 = JSON.parse(localStorage.getItem("periodsSelected1"));
		this.periodsSelected1.forEach(obj => {
			if (obj.period_id === this.activeYear.id) {
				obj['items'] = [];
			}
		})*/
		localStorage.setItem("periodsSelected1", JSON.stringify(this.periodsSelected1));
	}
	
	public unauthContinueSurvey(): void {
		this.unauthGetQuestion("");
		setTimeout(() => {
			this.continueSurveyAfterYearSelection = true;
			this.askUserAboutChanges = false;
		}, 500);
	}
	
	public unauthNoChangesInNextYear(): void {
		this.periodsSelected = JSON.parse(localStorage.getItem("surveyDataV2"));
		let questionLeadTo = "";
		
		if (!this.isEmpty(this.periodsSelected[this.periodsSelected.length - 2]["attrs"])) {
			this.periodsSelected[this.periodsSelected.length - 1]["attrs"] = this.periodsSelected[this.periodsSelected.length - 2]["attrs"];
			questionLeadTo = this.periodsSelected[this.periodsSelected.length - 1]["attrs"]["question_id"];
			localStorage.setItem("surveyDataV2", JSON.stringify(this.periodsSelected));
		}
		
		console.log(questionLeadTo);
		this.unauthGetQuestion(questionLeadTo);
		setTimeout(() => {
			this.continueSurveyAfterYearSelection = true;
			this.askUserAboutChanges = false;
		}, 500);
		
	}
	
	public unauthRunSurveyForNextYear(): void {
		this.periodsForm.reset();
		this.router.navigate(["registration"], {queryParams: {passed: "true"}});
		window.scroll(0, 0);
	}
	
	public unauthPreviousQuestion(): void {
		this.periodsSelected = JSON.parse(localStorage.getItem("surveyDataV2"));
		let prevQ = [];
		this.periodsSelected.forEach(obj => {
			if (obj["active"]) {
				if (!this.isEmpty(obj["attrs"])) {
					prevQ = obj["attrs"]["previous_questions"];
				}
			}
		});
		console.log(prevQ);
		
		this._SurveyService.unauthGetQuestion({
			question_id: prevQ[prevQ.length - 1]
		}).subscribe(res => {
			this.QAObject = res.result;
			this.QAObject["tree_end"] = 0;
			for (let i = 0; i < this.QAObject.answers.length; i++) {
				for (let z = 0; z < this.QAObject.answers[i].attributes_id.length; z++) {
					if (this.QAObject.answers[i].attributes_id[z].type > 0) {
						this.QAObject["type"] = this.QAObject.answers[i].attributes_id[z];
					}
				}
			}
			this.periodsSelected.forEach(obj => {
				if (obj["active"]) {
					if (!this.isEmpty(obj["attrs"])) {
						obj["attrs"]["previous_questions"].pop();
						obj["attrs"]["question_id"] = res.result["id"];
					}
				}
			});
			
			this.periodsSelected1 = JSON.parse(localStorage.getItem("periodsSelected1"));
			this.periodsSelected1.forEach(obj => {
				if (obj.period_id === this.activeYear.id) {
					obj['items'].pop();
				}
			})
			/*if (this.periodsSelected1.length === 1 && this.periodsSelected1[0].items.length < 1) {
				console.log('qweqwe');
				this.periodsSelected1 = [];
			}*/
			localStorage.setItem("periodsSelected1", JSON.stringify(this.periodsSelected1));
			
			window.scroll(0, 0);
			localStorage.setItem("surveyDataV2", JSON.stringify(this.periodsSelected));
		}, err => {
			console.log(err);
		});
		
	}
}
