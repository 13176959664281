<!--<div class="accept-sms-wrapper">

  <form name="acceptSmsForm" id="acceptSmsForm" class="acceptSmsForm" (ngSubmit)="acceptSmsForm.valid && submitSms()" [formGroup]="acceptSmsForm" #f="ngForm" novalidate>

    <div class="form-group">
      <div class="input-wrapper sms_code">
        <label for="sms_code">
          {{ 'Registration.Sms_code' | translate }}
          <span class="required_asterisk">*</span>
        </label>
        <input type="text" class="form-control" name="sms_code" id="sms_code" formControlName="sms_code"/>
      </div>
      <div class="form-control-feedback"
           *ngIf="acceptSmsForm.controls['sms_code'].invalid && acceptSmsForm.controls['sms_code'].touched">
        <p *ngIf="acceptSmsForm.controls['sms_code'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
        <p *ngIf="acceptSmsForm.controls['sms_code'].errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
      </div>
    </div>

    <div class="controls-wrapper registration-forms">
      <button class="f-btn f-btn-main-action" type="submit" [disabled]='!acceptSmsForm.valid'>{{ 'Shared.Continue_registration' | translate }}</button>
    </div>
  </form>
</div>-->


<div class="accept-sms-tpl container content-container">
	<div class="row">
		<div class="main-content col-12">
			<div class="col-12 form-title">
				<p>{{ 'Registration.SMS_step_info' | translate }}</p>
			</div>
			<div class="confirm-sms-form-wrapper col col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
				<form name="acceptSmsForm" id="acceptSmsForm" class="acceptSmsForm" (ngSubmit)="acceptSmsForm.valid && submitSms()" [formGroup]="acceptSmsForm" [class.ng-submitted-error]="f.submitted && f.form.invalid" #f="ngForm" novalidate>
					
					<div class="form-group required_section sms_code">
						<div class="input-wrapper">
							<label for="sms_code">
								{{ 'Registration.Sms_code' | translate }}
								<span class="required_asterisk">*</span>
							</label>
							<input type="text" class="form-control" name="sms_code" id="sms_code" formControlName="sms_code"/>
						</div>
						<div class="form-control-feedback sms_code"
							 *ngIf="f.submitted && acceptSmsForm.controls['sms_code'].errors || acceptSmsForm.controls['sms_code'].errors && (acceptSmsForm.controls['sms_code'].dirty || acceptSmsForm.controls['sms_code'].touched)">
							<p *ngIf="acceptSmsForm.controls['sms_code'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
							<p *ngIf="acceptSmsForm.controls['sms_code'].errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
						</div>
					</div>
					
					<div class="form-controls">
						<div class="controls-wrapper">
							<button class="main-action-btn form-submit-btn" type="submit">
								{{ 'Shared.Continue_registration' | translate }}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
