<div class="container-fluid footer" id="footer" #globalFooter>
	<div class="row">
		<div class="container col col-12">
			<div class="address">
				<a [href]="'http://proton-tax.crio-server.com/about-us-' + lang">
					PROTON STEUERBERATUNGSGESELLSCHAFT MBH, STORKOWER STR. 113, 10407 BERLIN
				</a>
			</div>
			<div class="copyright">
				<i class="fas fa-copyright"></i>
				<span
					class="copyright-details">2020 Proton Steuerberatungsgesellschaft MBH. All rights reserved | </span>
				<a class="impressum" [href]="'http://proton-tax.crio-server.com/impressum-' + lang">Impressum</a>
				<span> | </span>
				<a class="protection" [href]="'http://proton-tax.crio-server.com/data-protection-' + lang">{{'User_data_protection.Link_item_name' | translate}}</a>
			</div>
		</div>
	</div>
</div>
