// INTERNAL MODULES
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from "@angular/router";

// MAIN MODULES
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { SharedLazyModule } from "./shared/modules/shared-lazy.module";

// SERVICES
import { EnvServiceProvider, AlertService, AuthenticationService, SharedService, RegistrationService, UserProfileService, SurveyService } from './_services';

// GUARDS
import { AuthGuard } from './_guards';

// INTERCEPTORS
import { JwtInterceptor, EncodeHttpParamsInterceptor } from './_helpers';

// EXTERNAL MODULES
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// COMPONENTS
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { RegistrationComponent } from './registration/registration/registration.component';
import { AcceptSmsComponent } from './registration/accept-sms/accept-sms.component';
import { SetupPwdComponent } from './registration/setup-pwd/setup-pwd.component';
import { SurveyComponent } from './survey/survey.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { Error404Component } from './error404/error404.component';
import { UnauthSurveyComponent } from './survey/unauth-survey/unauth-survey.component';
import { GreetingsComponent } from './greetings/greetings.component';
import { UserDataProtectionComponent } from './user-data-protection/user-data-protection.component';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    FrontPageComponent,
    RegistrationComponent,
    AcceptSmsComponent,
    SetupPwdComponent,
    SurveyComponent,
    UserProfileComponent,
    Error404Component,
    UnauthSurveyComponent,
    GreetingsComponent,
    UserDataProtectionComponent
  ],
  imports: [
    SharedLazyModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    RouterModule,
    LoadingBarHttpClientModule,
  ],
  providers: [
    EnvServiceProvider,
    AlertService,
    AuthGuard,
    AuthenticationService,
    SharedService,
    RegistrationService,
    UserProfileService,
    SurveyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

