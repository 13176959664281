import {Component, ElementRef, Renderer2, ViewChild} from "@angular/core";
import {AlertService, EnvService, SharedService} from "./_services";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.less"]
})
export class AppComponent {
	@ViewChild("main") main;
	lang: string;
	dataProtection = false;
	
	constructor(
		private env: EnvService,
		private alertService: AlertService,
		private translate: TranslateService,
		private _SharedService: SharedService,
		public router: Router,
		public route: ActivatedRoute,
		public renderer: Renderer2,
		public el: ElementRef
	) {
		this.route.queryParams.subscribe(res => {
			if (res["lang"]) {
				this.lang = res["lang"];
				this.router.navigate([], {
					relativeTo: this.route,
					queryParams: {}
				});
			} else {
				this.lang = localStorage.getItem("lang");
				if (!this.lang) {
					this.lang = "ru";
				}
			}
			translate.use(this.lang);
			localStorage.setItem("lang", this.lang);
			this._SharedService.changeLanguageEvent.emit(this.lang);
		});
		
		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.dataProtection = JSON.parse(localStorage.getItem("dataProtection"));
				if (this.dataProtection === null) {
					localStorage.setItem("dataProtection", JSON.stringify(true));
				}
				if (this.route.snapshot.queryParams["device"]) {
					this.renderer.addClass(document.body, "device");
				}
			}
		});
	}
	
	error(message: string) {
		this.alertService.error(message);
	}
	
	/* ROUTE ANIMATION (CONFLICTS WITH NORMAL PAGE LAYOUT (HEADER => CONTENT => FOOTER) BECAUSE OF POS. ABSOLUTE OF THE ROUTER-OUTLET SIBLINGS) */
	/*public getRouterOutletState(outlet): any {
	  return outlet.isActivated ? outlet.activatedRoute : '';
	}*/
	
}
