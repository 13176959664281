import {Component, OnInit, ElementRef, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {SharedService} from "../../../_services"

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.less"]
})
export class FooterComponent implements OnInit {

	@ViewChild("globalFooter") globalFooter: ElementRef;
	lang = localStorage.getItem("lang");

	constructor(
		public router: Router,
		public route: ActivatedRoute,
		private _SharedService: SharedService
	) {
		/*if (this.lang === "uk") {
			this.lang = "ua"
		}*/
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
      this.lang = res;
			/*if (res === "uk") {
				this.lang = "ua"
			} else {
				this.lang = res;
			}*/
		});
	}

	ngOnInit() {
	}
}
