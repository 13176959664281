import {Component, OnInit, Input} from "@angular/core";
import {AlertService, RegistrationService, AuthenticationService} from "../../_services";
import {FormGroup, FormBuilder, Validators} from "@angular/forms";

@Component({
	selector: "app-setup-pwd",
	templateUrl: "./setup-pwd.component.html",
	styleUrls: ["./setup-pwd.component.less"]
})
export class SetupPwdComponent implements OnInit {
	/*@Input() phone_code: string;
	@Input() phone_number: string;*/
	setupPwdForm: FormGroup;
	
	constructor(
		private _RegistrationService: RegistrationService,
		private _alertService: AlertService,
		private fb: FormBuilder,
		private _AuthService: AuthenticationService
	) {
	}
	
	ngOnInit() {
		this.createForm();
	}
	
	createForm(): void {
		this.setupPwdForm = this.fb.group({
			password: ["", [Validators.minLength(6), Validators.required]],
			password_confirmation: ["", [Validators.minLength(6), Validators.required]]
		});
	}
	
	public setupPassword(): void {
		let objToSent = JSON.parse(localStorage.getItem("tempUserData"));
		objToSent["password"] = this.setupPwdForm.value.password;
		let savedSurveyData = JSON.parse(localStorage.getItem("surveyDataV2"));
		let periodsSelected1 = JSON.parse(localStorage.getItem("periodsSelected1"));
		
		function isEmpty(obj) {
			for (let key in obj) {
				if (obj.hasOwnProperty(key)) {
					return false;
				}
			}
			return true;
		}
		
		let temp = {
			phone_code: objToSent.phone_code,
			phone_number: objToSent.phone_number,
			password: this.setupPwdForm.value.password,
			last_question_id: "",
			last_answer_id: [],
			last_period_id: "",
			attributes: [],
			answers: periodsSelected1
		};
		if (savedSurveyData) {
			savedSurveyData.forEach(obj => {
				temp["attributes"].push({
					period_id: obj["id"],
					attrs: isEmpty(obj["attrs"]) ? [] : obj["attrs"]["attributes_id"]
				});
				if (obj["active"]) {
					temp["last_question_id"] = obj["attrs"]["question_id"];
					temp["last_answer_id"] = (Array.isArray(obj["attrs"]["answer_id"])) ? obj["attrs"]["answer_id"] : [obj["attrs"]["answer_id"]];
					temp["last_period_id"] = obj["id"];
				}
			});
		} else {
			temp["last_question_id"] = "";
			temp["last_answer_id"] = [];
			temp["last_period_id"] = "";
		}
		console.log(objToSent);
		// localStorage.removeItem("tempUserData");
		this._RegistrationService.setupPassword(objToSent)
		  .subscribe(res => {
			this._AuthService.login(temp);
			localStorage.removeItem('tempUserData');
		  }, error => {
			this._alertService.error('Alert.Opps_something_went_wrong');
		  });
	}
	
}
