import {Component, OnInit, ElementRef, Renderer2, ViewChild} from "@angular/core";
import {AuthenticationService, EnvService, SharedService} from "../../../_services";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.less"]
})
export class HeaderComponent implements OnInit {
	@ViewChild("mobilemenu") mobilemenu: ElementRef;
	
	myProfileLink: string = "";
	lang = localStorage.getItem("lang");
	linkLang = localStorage.getItem("lang");
	user: any = JSON.parse(localStorage.getItem("currentUser"));
	private loginDropdownToggle = false;
	
	constructor(
		private authenticationService: AuthenticationService,
		private translate: TranslateService,
		private _SharedService: SharedService,
		public router: Router,
		public route: ActivatedRoute,
		public el: ElementRef,
		public renderer: Renderer2,
		public _EnvService: EnvService
	) {
		if (this.linkLang === "uk") {
			this.linkLang = "ua"
		}
		this.myProfileLink = this._EnvService["myProfileLink"] + "login?lang=" + this.lang;
		this.router.events.subscribe((val) => {
			this.renderer.removeClass(this.mobilemenu.nativeElement, "open");
			if (this.loginDropdownToggle) {
				this.toggleDropdown();
			}
		});
	}
	
	public getUser(): void {
		this.user = JSON.parse(localStorage.getItem("currentUser"));
	}
	
	ngOnInit() {
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			this.lang = res;
			this.translate.use(this.lang);
			localStorage.setItem("lang", this.lang);
			this.myProfileLink = this._EnvService["myProfileLink"] + "login?lang=" + this.lang;
			if (res === "uk") {
				this.linkLang = "ua"
			} else {
				this.linkLang = res;
			}
		});
		this._SharedService.emitConnection
		.subscribe((res) => {
			console.log(res);
			this.getUser();
		});
	}
	
	public logout(): void {
		this.authenticationService.logout();
		this.getUser();
	}
	
	public useLanguage(lang): void {
		this._SharedService.changeLanguageEvent.emit(lang);
	}
	
	public toggleMobileMenu(): void {
		let hasClass = this.mobilemenu.nativeElement.classList.contains("open");
		if (hasClass) {
			this.renderer.removeClass(this.mobilemenu.nativeElement, "open");
		} else {
			this.renderer.addClass(this.mobilemenu.nativeElement, "open");
		}
	}
	
	public toggleDropdown(): void {
		this.loginDropdownToggle = !this.loginDropdownToggle;
	}
	
}
